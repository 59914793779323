<template>
  <!-- Multi Step Page -->
  <div class="create-page" v-if="ready">
    <div class="create-page__header">
      <CRow class="mr-0 ml-0">
        <CCol col="12" sm="6" class="d-flex align-items-center pl-0">
          <div class="zq-page-title-wrapper d-flex">
            <h3 class="zq-page-title">{{ isClone ? `Cloning: ${cloneName}` : texts.createPage.title }}</h3>
            <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
          </div>
        </CCol>
        <CCol col="12" sm="6">
          <ActionCreateBtns
            :currentStep="currentStep"
            :totalStep="totalStep"
            :finishAction="createEntity"
            @updateCurrentStep="updateCurrentStep"
            @next-step="nextStep"
          />
        </CCol>
      </CRow>
      <WizardHeader
        :currentStep="currentStep"
        :steps="steps"
        :isCompleted="isCompleted"
        @updateCurrentStep="updateCurrentStep"
      />
    </div>
    <div class="content">
      <FormBuilder
        v-if="currentStep === 0"
        :list="formList"
        @updated="updateSettingsData"
        :page="{ title: texts.createPage.title, info: descriptions }"
        :isCreateHeader="false"
        :isSettingsStep="true"
      />
      <CreateRules
        v-if="steps.find(step => step.key === 'rules') && currentStep === getStepNumber('rules')"
        context="achievement"
        @updateRulesData="updateRulesData"
        @updateStrategiesOperatorData="updateStrategiesOperatorData"
        @updateStrategiesPointsValueUpperData="updateStrategiesPointsValueUpperData"
        @updateStrategiesPointsValueData="updateStrategiesPointsValueData"
        @updateDataFromTemplate="updateDataFromTemplate"
        :rulesQueryData="rulesQueryData"
        :stepNumber="getStepNumber('rules') + 1"
        :strategiesData="strategies.pointsStrategy"
      />
      <AddProducts
        v-if="steps.find(step => step.key === 'productIds') && currentStep === getStepNumber('productIds')"
        :productsData="productsData"
        :isAdvanced="true"
        :descriptions="productsDescriptions"
        :stepNumber="getStepNumber('productIds') + 1"
        @updateProductsData="updateProductsData"
        @updateIsAllProductsInclude="updateIsAllProductsInclude"
        @updateCurrentTypes="updateProductsCurrentTypes"
      />
      <CreateEntrants
        v-if="steps.find(step => step.key === 'entrants') && currentStep === getStepNumber('entrants')"
        :entrantsData="entrantsData"
        :constraintsData="constraints"
        :descriptions="entrantDescriptions"
        @updateEntrantsData="updateEntrantsData"
        @updateLimitParticipants="updateLimitParticipants"
        @updateCanParticipate="updateCanParticipate"
        @updateConstraints="getConstraints"
        type="achievement"
      />
      <CreateDependantOn
        v-if="steps.find(step => step.key === 'dependantOn') && currentStep === getStepNumber('dependantOn')"
        @updateDependantOnData="updateDependantOnData"
        @resetDependantValidation="resetDependantValidation"
        @updateCurrentTypes="updateDependantOnCurrentTypes"
        @updateIsMission="updateIsMission"
        :isValid="dependantOnDataValidate"
        :dependantOnData="dependantOnData"
        :stepNumber="getStepNumber('dependantOn') + 1"
        :isKey="false"
      />
      <CreateScheduling
        ref="createScheduling"
        v-if="steps.find(step => step.key === 'scheduling') && currentStep === getStepNumber('scheduling')"
        @updateSchedulingData="updateSchedulingData"
        @resetValidate="resetSchedulingValidate"
        :schedulingData="schedulingData"
        :name="settingsData.name"
        :isFrom="schedulingDataValid_startDate"
        :isScheduleType="schedulingDataValid_scheduleType"
        :isEvery="schedulingDataValid_every"
        :isLimit="schedulingDataValid_scheduleOccurrencesLimit"
      />
      <CreateTranslations
        v-if="steps.find(step => step.key === 'translations') && currentStep === getStepNumber('translations')"
        :entityData="settingsData"
        :translatableFields="translatableFields"
        :translationsData="translationsData"
        @updated="updateTranslationsData"
        :stepNumber="getStepNumber('translations') + 1"
      />
      <CreateRewards
        v-if="steps.find(step => step.key === 'rewards') && currentStep === getStepNumber('rewards')"
        :pageNumber="getStepNumber('rewards') + 1"
        @updateRewardData="updateRewardData"
        @isRewardSaved="validateRewardSaved"
        :isRewardRankField="false"
        :rewardsData="rewardsData"
        :entityType="'Achievement'"
        :model="model"
      />
      <SummaryStep
        v-if="currentStep === getStepNumber('summary')"
        :settingsData="settingsData"
        :rulesData="rulesData"
        :dependantOnData="dependantOnData"
        :schedulingData="schedulingData"
        :rewardsData="rewardsData"
        :showEmptyRewardsData="true"
        :translationsData="translationsData"
        :entrantsData="entrantsData"
        :stepNumber="getStepNumber('summary') + 1"
        :model="model"
        :translatableFields="translatableFields"
        :strategiesData="strategies"
        :productsData="productsData"
      />
    </div>
  </div>
  <PreviewSpiner
    v-else
    :texts="['Validating...', 'Preparing...', 'Redirecting...']"
  />
  <!-- /Multi Step Page -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import ActionCreateBtns from '@/shared/components/steps/ActionCreateBtns';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import WizardHeader from '@/shared/components/steps/Header';
import CreateTranslations from '@/shared/components/supportModels/translations/CreateTranslations';
import CreateRules from '@/shared/components/supportModels/rules/CreateRules';
import CreateDependantOn from '@/shared/components/supportModels/dependantOn/CreateDependantOn';
import CreateScheduling from '@/shared/components/supportModels/scheduling/CreateScheduling';
import CreateRewards from '@/shared/components/supportModels/rewards/AddRewards';
import CreateEntrants from '@/shared/components/supportModels/entrants/CreateEntrants';
import SummaryStep from '@/shared/components/steps/SummaryStep';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import { translationsTransform } from '@/utils/translationsUtils';
import { stepSubTitles } from '@/config/pageTexts/stepSubTitles.json';
import { achievements } from '@/config/descriptions/achievements.json';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';
import achievementFields from '@/generated/ziqni/store/modules/achievements/fields';
import { dateUTC } from '@/utils/dateUTC';
import { cloneDeep, startCase } from 'lodash';
import { achievementQuery } from '@/helpers/rules/achievementQuery';
import { competitions } from "@/config/descriptions/competitions.json";
import PreviewSpiner from "@/shared/UI/Spiner";
import AddProducts from '@/shared/components/supportModels/products/AddProducts';

export default {
  name: 'CreateAchievement',
  components: {
    ActionCreateBtns,
    IconWithTooltip,
    WizardHeader,
    CreateTranslations,
    CreateRules,
    CreateDependantOn,
    CreateScheduling,
    CreateRewards,
    CreateEntrants,
    SummaryStep,
    PreviewSpiner,
    AddProducts
  },
  data() {
    return {
      model: 'achievements',
      currentStep: 0,
      totalStep: 0,
      descriptions: {
        ...achievements.create,
      },
      texts: {
        ...achievementsTexts,
      },
      steps: [],
      stepKeys: [],
      firstStep: {
        title: 'Settings',
        subTitle: stepSubTitles.settings,
        step: 0,
      },
      lastStep: {
        title: 'Summary',
        subTitle: stepSubTitles.summary,
        key: 'summary',
        step: 2,
      },
      formList: [],
      settingsData: {},
      translationsData: {},
      translationsMap: {},
      requiredFields: [],
      translatableFields: [],
      dependantOnData: {
        isMission: false,
        currentTypes: {},
        formData: {
          shouldMatchAtLeast: null,
          dependantOn: {
            must: [],
            mustNot: [],
            should: [],
            shouldMatchAtLeast: 0,
          }
        },
        selectedData: []
      },
      dependantOnDataValidate: null,
      schedulingData: {
        scheduleOccurrencesLimit: 1,
        maxNumberOfIssues: null,
        scheduleType: '',
        constraints: [],
        endDate: null,
        startDate: new Date(),
        every: [],
      },
      entrantsData: {
        currentTypes: {},
        selectedData: [],
        limitParticipants: false,
        canParticipate: true,
        optinRequiredForEntrants: false,
        formData: {
          maxNumberOfEntrants: null,
          minNumberOfEntrants: 0,
          shouldMatchAtLeast: 1,
          must: [],
          mustNot: [],
          should: []
        }
      },
      constraints: [],
      schedulingDataValid_startDate: null,
      schedulingDataValid_every: null,
      schedulingDataValid_scheduleOccurrencesLimit: null,
      schedulingDataValid_scheduleType: null,
      rewardsData: [],
      isRewardSaved: true,
      transformedRewards: [],
      rulesData: cloneDeep(achievementQuery),
      entrantsStep: {
        label: 'Entrants',
        key: 'entrants'
      },
      productTagsStep: {
        label: 'Products',
        key: 'productIds',
        subTitle: 'Products tags',
      },
      stepsOrder: {
        scheduling: 1,
        entrants: 2,
        dependantOn: 3,
        rewards: 4,
        rules: 5,
        productIds: 6,
        translations: 7,
      },
      rulesQueryData: {},
      isCompleted: false,
      ready: true,
      strategies: {
        pointsStrategy: {
          operator: 'GreaterThanEquals',
          pointsValueUpper: null,
          pointsValue: 100
        }
      },
      productsData: {
        currentTypes: {},
        isAllProductsInclude: true,
        selectedData: [],
        formData: {
          shouldMatchAtLeast: 1,
          dependantOn: {
            must: [],
            mustNot: [],
            should: []
          }
        }
      },
      isClone: false,
      cloneName: ''
    };
  },
  computed: {
    ...mapGetters('achievements', ['achievements', 'message', 'loading']),
    ...mapGetters('languages', {storeLanguages: 'languages'}),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
    entrantDescriptions() {
      return {
        ...competitions.create.multi.entrants,
      }
    },
    productsDescriptions() {
      return {
        ...competitions.create.multi.products,
      }
    }
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('achievements', ['handleCreateAchievements', 'handleGetAchievementToClone', 'handleGetAchievements']),
    ...mapActions('tags', ['handleGetTags', 'handleGetTagsByQuery']),
    ...mapActions('rewardTypes', ['handleGetRewardTypes']),
    ...mapActions('languages', ['handleGetLanguages']),
    ...mapActions('products', ['handleGetProducts']),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      // Achievement Clone
      if (this.$route.query.cloneId) {
        this.isClone = true;
        this.ready = false;
        this.handleGetAchievementToClone({id: this.$route.query.cloneId})
          .then(async data => {
            this.cloneName = data[0].name
            await this.setCloneData(data[0]);
            this.ready = true;
          });
      }

      this.formList = fieldHelpers.prepareCreateFormList(
        achievementFields,
        achievementsTexts.createPage,
        achievements.create
      );

      this.formList = this.formList.filter(item => !['maxNumberOfIssues'].includes(item.key));

      let formSteps = [];

      this.formList.forEach(field => {
        if (field.type.indexOf('_OBJECT') !== -1) {
          formSteps.push(field);
        }
        if (field.required && field.key !== 'constraints') {
          this.requiredFields.push(field.key)
        }
      })

      formSteps.push(this.entrantsStep, this.productTagsStep);

      if (formSteps.length) {
        let objectTypes = [];
        formSteps.forEach(step => {
          objectTypes.push(step.type)
          if (step.key === 'scheduling') {
            this.requiredFields.scheduling = [];
            this.requiredFields.scheduling.push('scheduleType')
          }
        });
        this.formList = this.formList.filter(formItem => {
          return !objectTypes.includes(formItem.type)
        });

        this.steps.push(this.firstStep);

        formSteps.forEach(step => {
          this.steps.push({
            title: startCase(step.label),
            subTitle: stepSubTitles[step.key],
            key: step.key,
            step: this.stepsOrder[step.key],
          });
          this.stepKeys.push(step.key);
        })

        this.steps.sort((a, b) => a.step > b.step ? 1 : -1)

        this.translatableFields = achievementFields.baseFields.translatableFields;

        this.lastStep.step = this.steps.length;
        this.steps.push(this.lastStep);
        this.totalStep = formSteps.length + 1;
      }
    },
    async setCloneData(achievementData) {
      const achievementFormList = fieldHelpers.prepareEditFormList(
          achievementFields,
          this.texts.editPage,
          this.descriptions,
          achievementData
      );
      const availableFormFields = this.formList.map(item => item.key);
      this.formList = achievementFormList.filter(item => availableFormFields.includes(item.key));

      // Scheduling
      this.schedulingData.scheduleType = achievementData.scheduling.scheduleType;
      this.schedulingData.scheduleOccurrencesLimit = achievementData.scheduling.scheduleOccurrencesLimit;
      this.schedulingData.startDate = achievementData.scheduling.startDate;
      this.schedulingData.endDate = achievementData.scheduling.endDate;

      if (achievementData.maxNumberOfIssues) {
        this.schedulingData.maxNumberOfIssues = achievementData.maxNumberOfIssues;
      }

      // Dependant On
      if (achievementData.achievementDependencies) {
        const achIds = Object.values(achievementData.achievementDependencies)
            .flat()
            .filter(e => typeof e === 'string' && e !== '');

        await this.getSelectedAchievements(achIds);

        const must = this.getCurrentTypeValue(achievementData.achievementDependencies.must, 'must');
        const should = this.getCurrentTypeValue(achievementData.achievementDependencies.should, 'should');
        const mustNot = this.getCurrentTypeValue(achievementData.achievementDependencies.mustNot, 'mustNot');

        const currentTypes = {...must, ...should, ...mustNot};

        const selectedData = cloneDeep(this.achievements);

        const formData = {
          dependantOn: cloneDeep(achievementData.achievementDependencies),
          shouldMatchAtLeast: null
        }

        selectedData.map((item) => {
          item.valid = formData.dependantOn.mustNot.includes(item.id) || formData.dependantOn.must.includes(item.id) || formData.dependantOn.should.includes(item.id);
          return item;
        })

        this.updateDependantOnData({currentTypes, formData, selectedData});
      }

      // Rules
      this.rulesQueryData = this.getCloneRulesData(achievementData.rules);

      for (let key in this.rulesQueryData) {
        if (this.rulesQueryData[key]) {
          this.rulesData[key] = this.rulesQueryData[key];
        }
      }

      // Strategies
      this.strategies = cloneDeep(achievementData.strategies);

      // Entrants
      if (achievementData.memberTagsFilter) {
        const keyArray = Object.values(achievementData.memberTagsFilter)
          .flat()
          .filter(e => typeof e === 'string' && e !== '');

        const data = await this.handleGetTagsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'key',
                queryValues: keyArray
              }
            ],
            limit: keyArray.length
          }
        });

        const selectedData = data.map(item => ({...item, valid: true}))

        const canParticipate = !keyArray.length;

        const must = this.getCurrentTypeValue(achievementData.memberTagsFilter.must, 'must');
        const mustNot = this.getCurrentTypeValue(achievementData.memberTagsFilter.mustNot, 'mustNot');
        const should = this.getCurrentTypeValue(achievementData.memberTagsFilter.should, 'should');

        const currentTypes = {...must, ...should, ...mustNot};

        const formData = {
          maxNumberOfEntrants: achievementData.maxNumberOfEntrants ?? null,
          minNumberOfEntrants: achievementData.minNumberOfEntrants ?? 1,
          must: achievementData.memberTagsFilter.must,
          mustNot: achievementData.memberTagsFilter.mustNot,
          should: achievementData.memberTagsFilter.should,
          shouldMatchAtLeast: achievementData.memberTagsFilter.shouldMatchAtLeast
        };
        const limitParticipants = !![
          ...achievementData.memberTagsFilter.must,
          ...achievementData.memberTagsFilter.mustNot,
          ...achievementData.memberTagsFilter.should
        ].length;
        const optinRequiredForEntrants = achievementData.constraints.includes('optinRequiredForEntrants');

        this.updateEntrantsData({canParticipate, selectedData, currentTypes, formData, limitParticipants, optinRequiredForEntrants})
      }

      // Rewards
      if (achievementData.rewards.length) {
        const cloneRewards = cloneDeep(achievementData.rewards);
        const rewardTypeIds = cloneRewards.map(reward => reward.rewardTypeId);
        const rewardTypes = await this.handleGetRewardTypes({idArray: rewardTypeIds});
        cloneRewards.forEach(reward => {
          reward.rewardType = rewardTypes.find(rewardType => rewardType.id === reward.rewardTypeId);
        })
        this.updateRewardData(cloneRewards);
      }

      // Products
      if (achievementData.productIds.length) {
        this.productsData.selectedData = await this.handleGetProducts({idArray: achievementData.productIds});
        this.productsData.isAllProductsInclude = false;
      } else if (achievementData.productTagsFilter && Object.keys(achievementData.productTagsFilter).length) {
        const keyArray = [
          ...achievementData.productTagsFilter.must,
          ...achievementData.productTagsFilter.mustNot,
          ...achievementData.productTagsFilter.should
        ];

        this.productsData.selectedData = await this.handleGetTagsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'key',
                queryValues: keyArray
              }
            ],
            limit: keyArray.length
          }
        });

        this.productsData.isAllProductsInclude = false;
        this.productsData.formData.shouldMatchAtLeast = achievementData.productTagsFilter.shouldMatchAtLeast;
        this.productsData.formData.dependantOn.must = achievementData.productTagsFilter.must;
        this.productsData.formData.dependantOn.mustNot = achievementData.productTagsFilter.mustNot;
        this.productsData.formData.dependantOn.should = achievementData.productTagsFilter.should;
      }

      // Translations
      if (achievementData.translations) {
        const languages = await this.handleGetLanguages({idArray: []});
        const map = this.getMapLanguages(languages)

        const achTranslations = achievementData.translations.map(item => {
          const translations = item.translations.reduce((acc, translation) => {
            return {...acc, [translation.fieldName]: translation.text}
          }, {})
          return {languageKey: item.languageKey, translations: translations}
        }).reduce((obj, item) => {
          obj[item['languageKey']] = item.translations
          return obj
        }, {});

        const val = languages.reduce((acc, lang) => {
          const language = achTranslations[lang.key]
              ? {[lang.name]: achTranslations[lang.key]}
              : {[lang.name]: {}}
          return {...acc, ...language}
        }, {})

        this.updateTranslationsData({map, val})
      }
    },
    getMapLanguages(languages) {
      return languages.reduce((acc, language) => {
        const lang = {[language.name]: language.key}
        return {...acc,  ...lang};
      }, {})
    },
    getCurrentTypeValue(arr, typeValue) {
      return arr.reduce((accumulator, value) => {
        return {...accumulator, [value]: typeValue};
      }, {});
    },
    async getSelectedAchievements(ids) {
      return await this.handleGetAchievements({idArray: ids})
    },
    cleanRules(rules) {
      if (rules.then && rules.then.length) {
        rules.then = rules.then.map(t => this.cleanThen(t));
      }
      if (rules.rules === null) {
        rules.rules = [];
      } else if (rules.rules.length) {
        rules.rules = rules.rules.map(r => this.cleanRules(r));
      }

      return rules;
    },
    cleanThen(then) {
      if (then.arguments === null) {
        then.arguments = [];
      }

      return then;
    },
    getCloneRulesData(rules) {
      let query = {};
      if (rules.length) {
        rules.forEach(action => {
          for (let key in action.rules) {
            if (action.rules[key] === null) {
              if (key === 'rules') {
                action.rules[key] = [];
              } else {
                action.rules[key] = '';
              }
            } else if (key === 'rules') {
              action.rules[key] = action.rules[key].map(r => this.cleanRules(r));
            } else if (key === 'then' && action.rules[key] !== null && action.rules[key].length) {
              action.rules[key] = action.rules[key].map(t => this.cleanThen(t));
            }
          }
          query[action.action] = action.rules;
        });
      }

      return query;
    },
    dependantOnValidate() {
      if (this.getStepNumber('dependantOn') === this.currentStep) {
        if (this.dependantOnData.selectedData.some(item => item.valid !== true)) {
          this.dependantOnDataValidate = false;
        }
      }
    },
    updateCurrentStep(val) {
      this.dependantOnValidate();

      if (this.dependantOnDataValidate === false) return;

      this.currentStep = val;
    },
    nextStep() {
      let invalidFields = this.getInvalidFields(true);
      if (!invalidFields.length && this.dependantOnDataValidate !== false) {
        this.currentStep += 1;
      } else {
        this.setInvalidFields(invalidFields);
      }
      if (this.currentStep === this.getStepNumber('summary')) {
        this.isCompleted = true;
      }
    },
    getInvalidFields() {
      let result = [];
      //TODO: remove after implementing constraint
      //this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '')) {
          result.push(key);
        }
      }

      this.dependantOnValidate();

      if (this.getStepNumber('scheduling') === this.currentStep) {
        if (this.schedulingData.maxNumberOfIssues) {
          const value = this.schedulingData.maxNumberOfIssues;
          const isNumber = isFinite(value) && value === parseInt(value, 10);

          if (!isNumber || (isNumber && value > 9999)) {
            result.push('maxNumberOfIssues');
          }
        }

        for (let key in this.schedulingData) {
          if (this.requiredFields.scheduling.includes(key) && (this.schedulingData[key] === null || this.schedulingData[key] === '')) {
            result.push(key);
          }

          const endDate = new Date(this.schedulingData.endDate);
          const currentDate = new Date();
          if (this.schedulingData.endDate && endDate <= currentDate && this.$route.query.cloneId) {
            result.push('endDate');
            this.$refs.createScheduling.setInvalidEndDate();
          }
        }
      }
      if (this.getStepNumber('entrants') === this.currentStep) {
        if (!this.entrantsData.canParticipate) {
          const tagsFiltersLength = this.entrantsData.formData.must.length
            + this.entrantsData.formData.mustNot.length
            + this.entrantsData.formData.should.length;
          if (!tagsFiltersLength) {
            result.push('canParticipate');
          }
        }
      }

      if (this.getStepNumber('rewards') === this.currentStep && !this.isRewardSaved) {
        result.push('saveReward');
      }

      return result;
    },
    getStepNumber(key) {
      let entityObject = this.steps.find(step => step.key === key);
      if (entityObject !== undefined && entityObject.hasOwnProperty('step')) {
        return entityObject.step;
      } else {
        return -1;
      }
    },
    updateSettingsData(val) {
      this.settingsData = val;
    },
    updateDependantOnData(val) {
      this.dependantOnData = val;
    },
    resetDependantValidation() {
      this.dependantOnDataValidate = null;
    },
    updateDependantOnCurrentTypes(val) {
      this.dependantOnData.currentTypes = val;
    },
    updateIsMission(val) {
      this.dependantOnData.isMission = val;
    },
    updateSchedulingData(val) {
      this.schedulingData = val;
    },
    resetSchedulingValidate() {
      this.schedulingDataValid_startDate = null;
      this.schedulingDataValid_every = null;
      this.schedulingDataValid_scheduleOccurrencesLimit = null;
      this.schedulingDataValid_scheduleType = null;
    },
    updateTranslationsData(obj) {
      this.translationsMap = obj.map;
      this.translationsData = obj.val;
    },
    updateRewardData(rewards) {
      this.rewardsData = rewards;
      let localRewards = cloneDeep(rewards);
      let transformedRewards = [];
      if (localRewards.length) {
        localRewards.forEach(reward => {
          reward.rewardTypeId = reward.rewardType.id;
          delete reward.rewardType;
          delete reward.id;
          delete reward.spaceName;
          delete reward.created;
          delete reward.entityType;
          delete reward.entityId;
          delete reward.translatableFields;
          transformedRewards.push(reward);
        })
      }
      this.transformedRewards = transformedRewards;
    },
    validateRewardSaved(isRewardSaved) {
      this.isRewardSaved = isRewardSaved;
    },
    updateRulesData(val) {
      this.rulesQueryData = cloneDeep(val);
      for (let key in val) {
        if (val[key]) {
          this.rulesData[key] = val[key];
        }
      }
    },
    updateStrategiesOperatorData(val) {
      this.strategies.pointsStrategy.operator = val.key;
    },
    updateStrategiesPointsValueUpperData(val) {
      this.strategies.pointsStrategy.pointsValueUpper = val;
    },
    updateStrategiesPointsValueData(val) {
      this.strategies.pointsStrategy.pointsValue = val;
    },
    updateDataFromTemplate(val) {
      this.rulesQueryData = cloneDeep(val);
      for (let key in this.rulesData) {
        if (val[key]) {
          if (val[key].id) {
            delete val[key].id;
          }
          this.rulesData[key] = val[key];
        } else {
          this.rulesData[key] = achievementQuery[key];
        }
      }
    },
    updateEntrantsData(val) {
      this.entrantsData = val;
    },
    updateLimitParticipants(val) {
      this.entrantsData.limitParticipants = val;
    },
    updateCanParticipate(val) {
      this.entrantsData.canParticipate = val;
    },
    getConstraints(val) {
      this.entrantsData.optinRequiredForEntrants = !!val.includes('optinRequiredForEntrants')
      this.constraints = val;
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (!invalidFieldElement.classList.contains('zq--form-row')) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    getRules() {
      let rules = [];
      const rulesData = cloneDeep(this.rulesData)
      for (const action in rulesData) {
        if (rulesData[action].then && rulesData[action].then.length) {
          rulesData[action].then = rulesData[action].then.map(t => {
            if (!t.arguments) {t.arguments = []}
            return t;
          })
        }

        let ruleObject = {};

        ruleObject.entityId = '';
        ruleObject.action = action;
        ruleObject.context = 'achievement';
        ruleObject.rules = rulesData[action];
        ruleObject.rules.type = 'condition';
        ruleObject.rules.lineNumber = 1;

        rules.push(ruleObject);
      }

      return rules
    },
    getMemberTagsFilter(formData) {
      return {
        must: formData.must,
        mustNot: formData.mustNot,
        should: formData.should,
        shouldMatchAtLeast: formData.shouldMatchAtLeast,
      }
    },
    createSingleStep() {
      let invalidFields = this.getInvalidFields();
      if (!invalidFields.length) {
        this.createEntity();
      } else {
        this.setInvalidFields(invalidFields)
      }
    },
    updateProductsData(val) {
      this.productsData = val;
    },
    updateIsAllProductsInclude(val) {
      this.productsData.isAllProductsInclude = val;
    },
    updateProductsCurrentTypes(val) {
      this.productsData.currentTypes = val;
    },
    getProductTagsFilterTagsFilter() {
      return {
        must: this.productsData.formData.dependantOn.must.length ? this.productsData.formData.dependantOn.must : [],
        mustNot: this.productsData.formData.dependantOn.mustNot.length ? this.productsData.formData.dependantOn.mustNot : [],
        should: this.productsData.formData.dependantOn.should.length ? this.productsData.formData.dependantOn.should : [],
        shouldMatchAtLeast: this.productsData.formData.dependantOn.shouldMatchAtLeast,
      }
    },
    async getProductIds() {
      let productIds = [];

      if (this.productsData.isAllProductsInclude) {
        let products = await this.handleGetProducts({idArray: []});
        products.forEach(item => {
          productIds.push(item.id);
        });
      } else {
        if (this.productsData.selectedData.length && Object.hasOwn(this.productsData.selectedData[0], 'productRefId')) {
          this.productsData.selectedData.forEach(product => {
            productIds.push(product.id);
          })
        }
      }

      return productIds;
    },
    async createEntity() {
      let formData = {};

      if (Object.keys(this.settingsData).length) {
        delete this.settingsData.constraints;
        formData = {...this.settingsData};
      }

      formData.addConstraints = [];

      if (Object.keys(this.translationsData).length) {
        formData.translations = translationsTransform(this.translationsData, this.translationsMap);
      }
      if (this.transformedRewards.length) {
        formData.rewards = this.transformedRewards;
      }
      if (Object.keys(this.schedulingData).length && this.schedulingData.scheduleType) {
        formData.scheduling = this.schedulingData;
        if (this.schedulingData.maxNumberOfIssues) {
          formData.maxNumberOfIssues = this.schedulingData.maxNumberOfIssues;
        }
        delete formData.scheduling.maxNumberOfIssues;
      }
      if (Object.keys(this.rulesData).length) {
        formData.rules = this.getRules();
      }
      if (this.dependantOnData.isMission) {
        this.constraints.push('mission');
      } else  if (Object.keys(this.dependantOnData).length && this.dependantOnData.formData) {
        let isDependantOn = false;
        for (let key in this.dependantOnData.formData.dependantOn) {
          if (this.dependantOnData.formData.dependantOn[key].length) {
            isDependantOn = true;
          }
        }
        if (isDependantOn) {
          formData.achievementDependencies = this.dependantOnData.formData.dependantOn;
        }
      }

      if (formData.key && typeof formData.key === 'object') {
        formData.name = formData.key.name;
        formData.key = formData.key.key;
      }

      formData.memberTagsFilter = this.getMemberTagsFilter(this.entrantsData.formData);

      if (this.constraints.length) {
        formData.addConstraints = this.constraints;
      }

      formData.strategies = this.strategies;

      if (this.productsData.isAllProductsInclude) {
        formData.addConstraints.push('allProducts');
        formData.productIds = null;
        formData.productTagsFilter = null;
      } else {
        formData.productTagsFilter = null;
        formData.productIds = await this.getProductIds();
      }

      if (formData.productIds && !formData.productIds.length) {
        formData.productTagsFilter = this.getProductTagsFilterTagsFilter();
      }

      // onlyAggregateOnActiveDays
      if (formData.scheduling.onlyAggregateOnActiveDays) {
        delete formData.scheduling.onlyAggregateOnActiveDays
        formData.scheduling.constraints.push('onlyAggregateOnActiveDays')
      }

      const body = [];
      body.push(JSON.parse(JSON.stringify(formData)));

      this.ready = false;

      this.handleCreateAchievements({createAchievementRequestArray: body})
        .then(data => {
          if (data.length) {
            this.$router.push({
              name: 'PreviewAchievement',
              params: {
                id: data[0].id,
              }
            })
          } else {
            this.ready = true;
            console.log('Something went wrong');
          }
        })
        .catch(error => {
          this.ready = true;
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }
  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
}
</style>
